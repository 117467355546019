<template>
  <v-container fluid>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- <v-select
      label="Chapter"
      class="white"
      :items="data"
      :item-text="chapterised"
      item-value="id"
      v-model="id"
      :loading="api.isLoading"
      placeholder="Chapter"
      outlined
    ></v-select> -->
    <div v-for="prize in data" :key="prize.id">
      <v-row>
        <v-col cols="12" md="6">
         <p>{{prize.PrizeType.name}}</p>
        </v-col>
        <v-col cols="12" md="6">
          <template>
            <Quantity
              :callbackValue="getValue"
              :prize="prize"
              :selected="selected"
            />
          </template>
        </v-col>
      </v-row>
    </div>
    <div class="text-right">
      <v-btn  class="mr-3" text @click="$router.go(-1)"> {{$t("action.cancel")}} </v-btn>
      <v-btn
        @click="submit"
        :loading="api.isLoading"
        class="mr-3"
        elevation="2"
        color="primary"
      >
        {{$t("action.save")}}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import Quantity from "@/components/Bread/Prize/Quantity";
export default {
  props: ["data", "url", "callbackSuccess"],
  components: {
    Quantity,
  },
  data: () => ({
    selected: [],
    selectedIds: [],
    quantity: [],
    form: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    getValue(val, id) {
      if (this.selectedIds.length == 0) {
        this.selectedIds.push(id);
        this.quantity.push({ gamePrizeId: id, quantity: val });
      } else {
        let index = this.selectedIds.indexOf(id);
        if (this.selectedIds.includes(id)) {
          this.quantity[index].quantity = val;
        } else {
          this.selectedIds.push(id);
          this.quantity.push({ gamePrizeId: id, quantity: val });
        }
      }
    },
    submit() {
      for (var i = 0, l = this.quantity.length; i < l; i++) {
          if (this.quantity[i].quantity != 0) {
            this.form.push(this.quantity[i]);
        }
      }
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
  },

};
</script>
