<template>
  <v-text-field
    v-model="count"
    type="number"
    :label='$t("model.prop.quantity")'
    key="quantity"
    :value="prize.quantity"
    :disabled="prize.maxQuantity == 1"
    max="500"
    min="0"
    :loading="api.isLoading"
  ></v-text-field>
</template>

<script>
export default {
  props: ["prize", "callbackValue"],
  data: () => ({
    count: null,
    oldData: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  watch: {
    count: function (val) {
      this.callbackValue(val, this.prize.id);
    },
  },
  methods: {
    fetch() {
      this.api.url =
        this.$api.servers.backend +
        "/v1/event/" +
        this.$route.params.parentId +
        "/prize";
      this.$api.fetch(this.api);
    },
    filterItems(gamePrizeId, quantity) {
      if (gamePrizeId == this.prize.id) {
        this.count = quantity;
      }
      this.oldData.push({ gamePrizeId: gamePrizeId, quantity: quantity });
    },
  },
  created() {
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.items = resp;
      for (var i = 0, l = this.items.length; i < l; i++) {
        this.filterItems(this.items[i].gamePrizeId, this.items[i].quantity);
      }
    };
  },
  beforeMount() {
    this.count = this.prize.quantity;
    this.fetch();
  },
};
</script>

<style>
</style>