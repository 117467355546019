<template>
 <v-container>
  <v-card   :loading="api.isLoading">
    <v-card-title>{{$t("view.PageBackendEvent.manage_prize")}}</v-card-title>
    <v-card-text>
      <div>
        <GamePrize :callbackSuccess="callbackSuccess" :url="url" :data="data" />
      </div>
    </v-card-text>
  </v-card>
 </v-container>
</template>

<script>
import GamePrize from "@/components/Bread/Prize/GamePrize";
export default {
  props: ["url", "callbackSuccess",],
  components: {
    GamePrize,
  },
  data: () => ({
    selected: [],
    count: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      this.id = resp[0].id;
    };
    //EOC
  },
  methods: {
  
    fetch() {
      this.api.url =
        this.$api.servers.backend +
        "/v1/" +
        this.$_getLocale() +
        "/data/event/" +
        this.$route.params.parentId +
        "/game/prize";
      this.$api.fetch(this.api);
    },
    chapterised: (item) => item.PrizeType.name,
  },
 
  beforeMount() {
    this.fetch();
  },
};
</script>

<style>
</style>